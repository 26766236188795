
import validator from 'validator';

const validateCreateManagerManagementAdd = (data) => {
    let errors = {};

    const { name, email, status,mobile,country_id , country_code} = data;

    if (validator.isEmpty(name)) {
        errors.name = '이름은 필수 항목입니다.';
    }
    if (validator.isEmpty(email)) {
        errors.email = '이메일은 필수 항목입니다.';
    }else if (!validator.isEmail(email)) {
        errors.email = '잘못된 이메일 주소입니다.';
    }
    
    if (validator.isEmpty(mobile)) {
        errors.mobile = '핸드폰 번호는 필수 항목입니다.';
    }else if(mobile.length < 10){
        errors.mobile = '올바른 핸드폰번호를 입력해 주세요.';
    }
    if (validator.isEmpty(country_id)) {
        errors.country_id = '국가를 선택하세요.';
    }
    if (validator.isEmpty(status)) {
        errors.status = '상태를 선택하세요.';
    }
    if (!country_code) {
        errors.country_code = '국가 코드를 선택하세요.';
    }
    return {
        isInvalid: Object.keys(errors).length > 0,
        error: errors,
    };
};

export default validateCreateManagerManagementAdd;