<template>
    <div class="card">
        <Toast />
        <form>
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{ $t('Managers.EditManager.Header') }}</h5>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Managers.AddManager.Name') }}</label>
                            <InputText :class="`${error.name ? 'p-invalid' : ''}`" type="text"
                                :placeholder="$t('Managers.AddManager.PlaceName')" id="title2" v-model="name"
                                @keydown.space="preventLeadingSpace" onkeypress="return /[a-z A-Z]/i.test(event.key)"
                                maxlength="65"></InputText>
                            <div class="text-red">{{ error.name }}</div>
                        </div>
                    </div>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Managers.AddManager.Email') }}</label>
                            <InputText :class="`${error.email ? 'p-invalid' : ''}`" type="text"
                                :placeholder="$t('Managers.AddManager.PlaceEmail')" id="email"
                                @keydown.space="preventLeadingSpace" v-model="email" disabled></InputText>
                            <div class="text-red">{{ error.email }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-1 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Managers.AddManager.CountryCode') }}</label>
                            <Dropdown v-model="country_code" :options="dropdownCountryCodeValues" optionValue="code"
                                optionLabel="view_code" :placeholder="$t('Managers.AddManager.Select')"
                                :class="`${error.country_code ? 'p-invalid' : ''}`" />
                            <div class="text-red">{{ error.country_code }}</div>
                        </div>
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Managers.AddManager.Phone') }}</label>
                            <InputText :class="`${error.mobile ? 'p-invalid' : ''}`" type="text"
                                :placeholder="$t('Managers.AddManager.PlacePhone')" id="title2" maxlength="20"
                                onkeypress="return /[0-9]/i.test(event.key)" v-model="mobile"></InputText>
                            <div class="text-red">{{ error.mobile }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">

                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Managers.AddManager.Country') }}</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                                optionLabel="country_name" :placeholder="$t('Managers.AddManager.Select')" />
                            <div class="text-red">{{ error.country_id }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Managers.AddManager.Status') }}</label>
                            <Dropdown v-model="status" :options="dropdownstatusValues" optionValue="code"
                                optionLabel="name" :placeholder="$t('Managers.AddManager.Select')" :class="`${error.status ? 'p-invalid' : ''}`"/>
                            <div class="text-red">{{ error.status }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-d-flex p-jc-end p-ai-center" style="float: left">
                <Button :label="$t('Managers.AddManager.Save')" icon="pi pi-check" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="editManagerManagementPill"></Button>
            </div>
            <div class="p-d-flex p-jc-end p-ai-center">
                <div>
                    <Button :label="$t('Managers.AddManager.Back')" icon="pi pi-replay" iconPos="left"
                        class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import axios from 'axios';
import ManagerService from '../../../service/API/SuperAdmin/ManagerService.js';
import validateCreateManagerManagementAdd from '../../../validations/ManagerManagement/validateCreateManagerManagementAdd';
export default {
    name: 'ManagerManagementAdd',
    // props: ['dog', 'image'],
    data() {
        return {
            render1: false,
            dropdownstatusValues: [
                { name: '활성화', code: 'active' },
                { name: '비활성화', code: 'inactive' },
            ],
            dropdownstatusValue: null,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownCountryCodeValues: '',
            dropdownCountryCodeValue: null,

            name: '',
            email: '',
            mobile: '',
            country_id: '',
            status: '',
            country_name: '',
            code: '',
            view_code: '',
            country_code: '',

            formData: new FormData(),
            id: '',
            error: {},
        };
    },
    created() {
        this.managerService = new ManagerService();
    },
    mounted() {

        this.managerService
            .getCountrydropdown()
            .then((data) => {
                var newCountryList = data.data.data.country;
                for (let i = 0; i < newCountryList.length; i++) {
                    if (newCountryList[i].code == 'all') {
                        newCountryList.splice(i, 1);
                    }
                }
                this.dropdownCountryValues = newCountryList;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.newCountryList);
            })
            .catch((err) => console.log(err));

        this.managerService
            .getCountryCodedropdown()
            .then((data) => {
                var newCountryCodeList = data.data.data.country_code;
                // console.log("Code", data.data.data.country_code)
                for (let i = 0; i < newCountryCodeList.length; i++) {
                    if (newCountryCodeList[i].code == 0) {
                        newCountryCodeList.splice(i, 1);
                    }
                }
                this.dropdownCountryCodeValues = newCountryCodeList;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                // console.log(newCountryCodeList);
            })
            .catch((err) => console.log(err));


        this.managerService.viewManagerManagement(this.$route.params.id).then((res) => {
            // let phone_data_arr = res.data.data[0].mobile.split("-");
            this.id = res.data.data[0].id;
            this.name = res.data.data[0].name;
            this.email = res.data.data[0].email;
            this.mobile = res.data.data[0].mobile;
            // this.country_code = res.data.data.country_code;
            this.country_name = res.data.data[0].country_name;
            this.country_id = res.data.data[0].country_id;
            this.status = res.data.data[0].status;
            this.country_code = res.data.data[0].country_code;
            console.log(this.code)

            // this.mydata.type = res.data.data[0].type;
            console.log(res)
        });


    },
    methods: {


        editManagerManagementPill() {
            let vcheckData = {
                name: this.name,
                email: this.email,
                // type: this.dropdownValueType == null ? '' : 'something',
                mobile: this.mobile,
                country_id: this.country_id.toString(),
                status: this.status,
                country_code: this.country_code,
            };
            const { isInvalid, error } = validateCreateManagerManagementAdd(vcheckData);
            if (isInvalid) {
                this.error = error;
                console.log(error);
            } else {
                // let Phone = this.code + "-" + this.mobile;
                // console.log(this.file);
                this.formData.append('id', this.$route.params.id);
                this.formData.append('name', this.name);
                this.formData.append('email', this.email);
                this.formData.append('mobile', this.mobile);
                this.formData.append('country_id', this.country_id);
                this.formData.append('status', this.status);
                // this.formData.append('type', this.dropdownValueType?.name);
                // this.formData.append('status', this.dropdownValue.name===undefined ? this.dropdownValue :this.dropdownValue.name);
                console.log(this.formData);
                return axios.put('/asnhist-dev/api/v1/admin/manager/edit', { id: this.$route.params.id, name: this.name, email: this.email, mobile: this.mobile, country_id: this.country_id, country_code: this.country_code, status: this.status }).then(() => {
                    this.$router.push({ name: 'ManagerManagementlist' });
                    setTimeout(() => {
                        this.$toast.add({ severity: 'info', summary: '확인되었습니다.', detail: '매니저가 성공적으로 업데이트되었습니다.', life: 3000 });
                    }, 1000);
                });
            }
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
    },
};
</script>

<style scoped>
.img-info {
    font-size: 11px;
    font-weight: 400;
    color: rgb(48, 48, 48);
}

.custom-select {
    position: relative;
    border: 1px solid #cecece;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.custom-select-invalid {
    position: relative;
    border: 1px solid red;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.select-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    z-index: 5;
    opacity: 0;
}

.SelectBtn {
    max-width: 100px;
}

.custom-select span {
    max-width: 140px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

input#email {
    background: darkgray;
    color: black;
}
</style>